import React from "react";
import StripedTable from "../../../../stripedTable";

const VideoPackage = () => {
  return (
    <div className="text-center py-4">
      <div className="col-lg-6 mx-auto">
        <p className="lead mb-4">
          To have a clear understanding of your needs, we will discuss video
          deliverables, business goals and what the video(s) will be used for.
          Below are my starting rates based on short form video content, under
          60 seconds per video :{" "}
        </p>
        <StripedTable
          columnHeaders={["videos", "price"]}
          data={[
            ["1", "250€"],
            ["3", "550€"],
            ["5", "750€"],
          ]}
        />
        <div className="text-muted opacity-75 mt-2">
          subject to change based on creative requests
        </div>
      </div>
    </div>
  );
};

export default VideoPackage;
