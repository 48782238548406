import React from "react";
import SocialMediaStats from "../socialMediaStats";
import SponsoredPostsPlans from "./plans";

const SponsoredPosts = () => (
  <>
    <div className="text-center py-3">
      <div className="col-lg-6 mx-auto mt-1">
        <p className="lead mb-4 fs-4">
          These photos or/and videos will be posted directly to my audience to
          promote your brand / company.
        </p>
        <div className="d-grid col-8 offset-2 ">
          <SocialMediaStats />
        </div>
      </div>
      <div className="mt-4">
        <SponsoredPostsPlans />
      </div>
    </div>
  </>
);

export default SponsoredPosts;
