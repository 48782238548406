import React from "react";
import "./style.scss";
import StripedTable from "../../../../stripedTable";
import CollapsibleText from "../../../../collapsibleText";

const SingleMediaPackageOption = ({ title, imageUrl, price }) => {
  return (
    <div className="c-single-media-package-option col">
      <div
        className="card card-cover overflow-hidden rounded-4 shadow-lg"
        style={{
          backgroundImage: `url('${imageUrl}')`,
          height: 500,
          backgroundSize: "cover",
          objectPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="d-flex flex-column h-100 pb-3 text-white text-shadow-1">
          <div className="c-single-media-package-option__title p-3 py-md-4">
            <h3 className="mb-0">{title}</h3>
          </div>

          <div className="c-single-media-package-option__price p-3">
            <h3 className="mb-0">{price}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

const MediaPackageOptions = () => {
  return (
    <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">
      <SingleMediaPackageOption
        title="10 - 30 photos"
        price="333 €"
        imageUrl={
          "https://res.cloudinary.com/div038xr5/image/upload/c_pad,w_375,h_500,ar_3:4/v1721652100/ss-creations/collaborations/prices/photoPackages/IMG_6936_lmnitc.jpg"
        }
      />
      <SingleMediaPackageOption
        title="30 - 50 photos"
        price="555 €"
        imageUrl="https://res.cloudinary.com/div038xr5/image/upload/t_photoPackageDimension/v1721652099/ss-creations/collaborations/prices/photoPackages/IMG_5564_mzgs8z.jpg"
      />
      <SingleMediaPackageOption
        title="50 - 100 photos"
        price="888 €"
        imageUrl="https://res.cloudinary.com/div038xr5/image/upload/t_photoPackageDimension/v1721652097/ss-creations/collaborations/prices/photoPackages/IMG_7104_boz6jp.jpg"
      />
    </div>
  );
};

const PhotoPackage = () => {
  const photoPackagesData = [
    ["10 - 30 photos", "333 €"],
    ["30 - 50 photos", "555 €"],
    ["50 - 100 photos", "888 €"],
  ];

  const introTextParagraphs = [
    `        To have a clear understanding of your needs, we will discuss video
        deliverables, business goals and what the video(s) will be used for.
`,
    `        Also more photos means my stay needs to be approximately longer. Below
        are my starting rates based on high quality photo content. (subject to
        change based on creative requests):"
`,
  ];

  return (
    <div className="text-center py-4">
      <div className="col-lg-6 w-md-75 mx-auto">
        <div className="d-lg-none">
          <CollapsibleText>
            {introTextParagraphs.map((paragraph, index) => (
              <p key={index} className="text-lead">{paragraph}</p>
            ))}
          </CollapsibleText>
        </div>
        <div className="d-none d-lg-block">
          {introTextParagraphs.map((paragraph, index) => (
            <p key={index} className="text-lead">{paragraph}</p>
          ))}
        </div>
      </div>

      <div className="d-lg-none">
        <StripedTable
          columnHeaders={["photos", "price"]}
          data={photoPackagesData}
        />
      </div>
      <div className="d-none d-lg-block">
        <MediaPackageOptions data={photoPackagesData} />
      </div>
      <div className="col-lg-6 mx-auto text-muted opacity-75">
        If you would like also written content with it, it is a extra charge
        depending on what you prefer:
        <ul className="list-unstyled mt-2">
          <li className="">
            personal experience and true stories: 111 € / story
          </li>
          <li className="">short mindful thoughts: 77 € / thought</li>
        </ul>
      </div>
    </div>
  );
};

export default PhotoPackage;
