import React, { FC } from "react";
import { Link } from "gatsby";
import "./style.scss";

type Props = {
  title: string;
  to?: string;
  type?: "button" | "submit" | "reset";
};

const ButtonPrimary: FC<Props> = ({ title, to, type }) => {
  return (
    <div className="c-btn-primary d-grid col-8 offset-2 order-md-4 mt-md-3">
      {to ? (
        <Link
          to={to}
          className="btn btn-primary px-4 py-2 btn-md-lg fs-4 text-md-uppercase"
        >
          {title}
        </Link>
      ) : (
        <button
          type={type}
          className="btn btn-primary px-4 py-2 btn-md-lg fs-4 text-md-uppercase"
        >
          {title}
        </button>
      )}
    </div>
  );
};

export default ButtonPrimary;
