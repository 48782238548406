import React from "react";
import "./style.scss";
import PropTypes from "prop-types";

const SingleSponsoredPost = ({ title, price, features, color }) => (
  <div
    className={`c-single-sponsored-post c-single-sponsored-post--${color} col`}
  >
    <div className={`card mb-4 rounded-3 shadow-sm`} style={{ minHeight: 400 }}>
      <div className={`card-header py-3`}>
        <h4 className="my-0 fw-light h1">{title}</h4>
      </div>
      <div className="card-body d-flex flex-column">
        <span className="card-title pricing-card-title h1 fw-bold my-2">
          {price}
        </span>
        <ul className=" mt-3 mb-4 text-start flex-grow-1">
          {features.map((feature, index) => (
            <li className="my-2" key={index}>
              {feature}
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

SingleSponsoredPost.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  color: PropTypes.oneOf(["primary", "secondary", "tertiary"]).isRequired,
};

const SponsoredPostsPlans = () => (
  <div className="row row-cols-1 row-cols-md-3 mb-3 text-center mt-md-3">
    <SingleSponsoredPost
      color="secondary"
      title="Basic"
      price="333 €"
      features={[
        "A single post on two platforms of your choice",
        "5 - 8 photos + content/post",
        "A minimum of 3 days stay",
      ]}
      buttonText="Sign up for free"
    />
    <SingleSponsoredPost
      color="primary"
      title="Extra"
      price="666 €"
      features={[
        "3 posts on all my social media platforms",
        "5 - 8 photos + content/post",
        "3 videos on TikTok",
        "A minimum of 5 days stay",
      ]}
      buttonText="Get started"
    />
    <SingleSponsoredPost
      color="tertiary"
      title="All In"
      price="1222 €"
      features={[
        "A minimum of 1 post per day on all my social media platforms",
        "5 - 8 photos or video/post with my face on it",
        "70 photos and 5 videos for you to use for promotional purposes.",
        "A minimum of 7 days stay",
      ]}
      buttonText="Get started"
    />
  </div>
);

export default SponsoredPostsPlans;
