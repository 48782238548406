import React from "react";

type AccordionHeadingProps = {
  title: string;
  icon: string;
  isOpen: boolean;
};

const AccordionHeading = ({ title, icon, isOpen }: AccordionHeadingProps) => {
  return (
    <div
      className={`d-flex align-items-center gap-3 flex-grow-1 ${
        isOpen ? "justify-content-center" : ""
      }`}
    >
      <i className={`bi ${icon}`} style={{ fontSize: "2rem" }} />
      <h3 className="fs-4 fw-medium mb-0">{title}</h3>
    </div>
  );
};

export default AccordionHeading;
