import React from "react";
import Layout from "../../../components/layout";
import SponsoredPosts from "../../../components/collaboration/prices/sponsoredPosts";
import { Button } from "react-bootstrap";
import AdditionalPackagesAccordion from "../../../components/collaboration/prices/additionalPackages";
import ButtonPrimary from "../../../components/button-primary";

const CollaboratePrices = () => {
  return (
    <Layout>
      <div className="d-md-flex justify-content-center w-100">
        <div className="bg-dark p-5 py-1 rounded-bottom">
          <h1
            className="text-center display-3 text-light py-1 py-md-3 fw-ligh"
            style={{ opacity: 0.9 }}
          >
            Sponsored Posts <br className="d-md-none" /> Prices
          </h1>
        </div>
      </div>
      <div className="container py-4 pt-3">
        <div>
          <SponsoredPosts />

          <div className="text-center">
            <h3 className="display-5 fw-bold text-center">Additional Orders</h3>
            <p>
              In addition to one of the two packages you may request additional
              content. Please see the prices below.{" "}
            </p>
          </div>
          <AdditionalPackagesAccordion></AdditionalPackagesAccordion>

          <div className="text-center mt-5">
            <ButtonPrimary title="Order" to="/collaboration#contact" />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CollaboratePrices;
